
// Browse A
const hotspots = [
    {
        title:"Edit page",
        step:0,
        image:"01.jpg",
        content:"This tab opens the edit page. You need to select a track first and add it by clicking the pencil icon on the track card or clicking the 'customise music' button. ",
        position:{
            top:"6%",
            left:"15%"
        },
        offset:{
            top:30
        },
        element:".main-header ul li:nth-child(2)",
    },
    {
        title:"Sessions page",
        step:0,
        image:"02.jpg",
        content:"This tab displays your previously saved sessions. You can delete and rename them. Most recent sessions display at the top of the list.",
        position:{
            top:"6%",
            left:"20%"
        },
        offset:{
            top:30,
        },
        element:".main-header ul li:nth-child(3)",
    },
    {
        title:"Favourites",
        step:0,
        image:"03.jpg",
        content:"This tab displays your favourite tracks. You can remove these by clicking the favourite icon. New favourites are added to the bottom of the list.",
        position:{
            top:"6%",
            left:"25%"
        },
        offset:{
            top:30,
        },
        element:".main-header ul li:nth-child(4)",
    },
    {
        title:"Music collections",
        step:0,
        image:"04.jpg",
        content:"Start your music search by selecting a collection. Choose your favourite way of searching: either by mood, music genre, video genre, instrumental palette, or game genre.",
        position:{
            top:"20%",
            left:"15%"
        },
        offset:{
            top:20
        },
        condition:{
            type:"state",
            variable:"edit/mp3_files_loaded",
            operator:"EQUALS",
            value:"true"
        },
        element:".collection-item:nth-child(2)",
    },
    {
        title:"Knowledge base",
        step:0,
        image:"05.jpg",
        content:"This is where you can find tutorials, walkthrough videos and more.",
        position:{
            top:"61%",
            left:"5%"
        },
        offset:{
            left:30
        },
        popup_position:"right-end",
        element:".sidebar-bottom-menu li.knowledge-base-link",
    },
    {
        title:"Contact us",
        step:0,
        image:"06.jpg",
        content:"This takes you straight to our FAQs which includes a link to our contact page. We're based in the UK and are available between 9-5 on weekdays.",
        position:{
            top:"68%",
            left:"5%"
        },
        offset:{
            left:30
        },
        popup_position:"right-end",
        element:".sidebar-bottom-menu li.contact-link",
    },
    {
        title:"My account",
        step:0,
        image:"07.jpg",
        content:"This section contains a link to your account as well as information about the current version of Filmstro and preferences, where you can toggle the sample rate if you experience audio issues. ",
        position:{
            top:"76%",
            left:"5%"
        },
        popup_position:"right-end",
        offset:{
            left:30
        },
        element:".user-profile-link",
    },
    {
        title:"Search bar",
        step:0,
        image:"08.jpg",
        content:"You can search our library by typing in a key word. We recommend searching via the albums combined with the music genre filters for better results.",
        position:{
            top:"50%",
            left:"50%"
        },
        offset:{
            top:0,
            left:50
        },
        element:".search-area input",
    },


    {
        title:"Add your video while browsing",
        step:0,
        image:"09.jpg",
        content:`You can add an mp4 video which will play back synchronised to each track you preview while you're browsing music. <span class="filmstro-yellow">Tip:</span> Videos are loaded into your browser's temporary storage, not uploaded to Filmstro.`,
        position:{
            top:"36%",
            left:"96%"
        },
        offset:{
            top:100,
        },
        element:".video-box-wrapper",
    },
    {
        title:"Sync your edit to the music",
        step:0,
        image:"10.jpg",
        content:"Turn this on to watch your video while you're browsing. You can also control the video volume separately or mute it entirely to focus on the music without hearing your VO, foley or SFX.",
        position:{
            top:"37%",
            left:"75%"
        },
        condition:{
            type:"state",
            variable:"video_source",
            operator:"NOT_EMPTY",
            value:false
        },
        offset:{
            top:-40
        },
        element:".sync-music-button",
    },
    {
        title:"Music albums",
        step:1,
        image:"11.jpg",
        content:`The albums contain all of our music and are manually curated by our music supervisors. <span class="filmstro-yellow">Tip:</span> Can't find what you're looking for? Let us know which album you need and we'll create it for you. `,
        position:{
            top:"16%",
            left:"41%"
        },
        offset:{
            top:10
        },
        element:".album-card",
    },
    {
        title:"Audio watermark",
        step:1,
        image:"12.jpg",
        content:"If you don't have a license then our customizable music will contain an audio-watermark. You can remove it here by choosing a license to unlock all of our content.",
        position:{
            top:"6%",
            left:"86%"
        },
        offset:{
            top:10,
        },
        element:".remove-btn",
    },
    {
        title:"Music tracks",
        step:1,
        image:"13.jpg",
        content:"Hover over a music card to see its meta-data. Then press play to preview the music, add it to your favourites and open it in the edit page to customise it.",
        position:{
            top:"66%",
            left:"46%"
        },
        element:".music-card:nth-child(3)",
    },
    {
        title:"Music genre filters",
        step:1,
        image:"14.jpg",
        content:`Refine your search by the 4 main categories of sound: <br><strong>Cinematic</strong> uses movie-scoring instruments. <br><strong>Amped</strong> is a typical band set up. <br><strong>Acoustic</strong> is 'unplugged' music. <br><strong>Electronic</strong> is mostly digital music.`,
        position:{
            top:"54%",
            left:"90%"
        },
        offset:{
            top:10,
            left:-70
        },
        element:".select-box .select-form",
    },
    {
        title:"Download or customize",
        step:1,
        image:"15.jpg",
        content:"Once you select a track you can open it in the Edit page to customise it OR you can simply download our ready-made mix for use in your productions.",
        position:{
            top:"90%",
            left:"56%"
        },
        offset:{
            top:10
        },
        element:".footer-actions .custome-button",
    },
    {
        title:"Music volume",
        step:1,
        image:"16.jpg",
        content:"Set the volume of the music and balance it with your video's volume to get a quick idea of how the music will compliment your edit. ",
        position:{
            top:"88%",
            left:"84%"
        },
        offset:{
            top:-10,
            left:-90
        },
        element:".volume-slider-parent",
    },

    {
        title:"Music templates",
        step:2,
        image:"17.jpg",
        content:"Select a template as a starting point to begin customising a track. Choose the Composer's mix for a good starting place or a blank template to start your own from scratch.",
        position:{
            top:"20%",
            left:"14%"
        },
        condition:{
            type:"state",
            variable:"edit/current_edit_view",
            operator:"EQUALS",
            value:"presets"
        },
        offset:{
            top:10
        },
        element:".single-preset",
    },
    {
        title:"Add your video while editing",
        step:2,
        image:"18.jpg",
        content:`You can add an mp4 video so you can make musical choices based on your edit. <span class="filmstro-yellow">Tip:</span> Latest version of Chrome required. For maximum performance, close other browsers and web apps. Max file size 500 MB.`,
        position:{
            top:"28%",
            left:"84%"
        },
        offset:{
            top:100,
        },
        element:".video-box-wrapper",
    },
    {
        title:"Basic info & favourites",
        step:2,
        image:"19.jpg",
        content:"This area shows you the BPM, Time signature and Key signature of the music, along with an icon to add it to your favourites. ",
        position:{
            top:"54%",
            left:"14%"
        },
        element:".editbar-text",
    },

    {
        title:"Transitions",
        step:3,
        image:"20.jpg",
        content:`Use the IN shape to determine the shape of the musical transition at the <strong>start</strong> of your selection and the OUT shape to determine the shape of the musical transition at the <strong>end</strong> of your selection.`,
        position:{
            top:"18%",
            left:"17%"
        },
        condition:{
            type:"state",
            variable:"edit/current_edit_view",
            operator:"EQUALS",
            value:"transitions"
        },
        offset:{
            top:40
        },
        element:".transitions-wrapper:nth-child(2)",
    },
    {
        title:"Momentum, Depth and Power toggle",
        step:3,
        image:"21.jpg",
        content:`Select which parameter is actively displayed in the timeline for edits such as adding transitions and adding transition ramps. <span class="filmstro-yellow">Tip:</span> You can also use your keyboard number keys 1,2,3 to toggle between them.`,
        position:{
            top:"46%",
            left:"25%"
        },
        element:".radio-boxes",
    },
    {
        title:"Transport controls",
        step:3,
        image:"22.jpg",
        content:"Press Play to start the playhead. Stop>Play plays from the beginning of the track or the beginning of an active timeline Block selection. Use spacebar to play and pause.",
        position:{
            top:"46%",
            left:"44%"
        },
        offset:{
            top:10,
        },
        element:".theme-play-actions",
    },
    {
        title:"Scoring Sliders",
        step:3,
        image:"23.jpg",
        content:`Use the scoring sliders to create musical keyframes in the timeline. <span class="filmstro-yellow">Tip:</span> <strong>De-select</strong> the timeline to record in real time. Select one or more <strong>blocks</strong> to apply changes to the entire selection. `,
        position:{
            top:"25%",
            left:"48%"
        },
        offset:{
            left:-10
        },
        element:".filmstro-slider-wrapper:nth-child(2)",
    },
    {
        title:"Additional settings",
        step:3,
        image:"24.jpg",
        content:"<strong>Snap</strong> restricts the input to bottom, middle or top. <strong>Audition</strong> allows you to play with the scoring sliders without recording any input. <br><strong>Latch</strong> mode applies your input to the entire timeline following on from the current playhead position.",
        position:{
            top:"13%",
            left:"58%"
        },
        offset:{
            left:50
        },
        element:".sliders-actions-toggler",
    },
    {
        title:"Metronome",
        step:3,
        image:"25.jpg",
        content:"This is a feature to allow you to still hear the main pulse of the music (in case that's relevant to your editing decisions) during passages where the music is ambiguous or very quiet. ",
        position:{
            top:"45%",
            left:"73%"
        },
        offset:{
            top:-30
        },
        element:".timeline-action-metronome",
    },
    {
        title:"Eraser",
        step:3,
        image:"26.jpg",
        content:"Select one or more <strong>blocks</strong> and use the eraser to remove all three parameters including any transitions. This resets the <strong>block</strong> to all 3 scoring sliders being on the left with no transitions applied. ",
        position:{
            top:"45%",
            left:"78%"
        },
        offset:{
            top:-30
        },
        element:".timeline-action-reset",
    },
    {
        title:"Ramp transition",
        step:3,
        image:"27.jpg",
        content:"Multi-select a number of <strong>blocks</strong> which contain a difference in data and apply a global transition (ramp) across the entire selection. ",
        position:{
            top:"45%",
            left:"83%"
        },
        offset:{
            top:-30
        },
        element:".timeline-action-ramp",
    },
    {
        title:"Lengthen & shorten a track",
        step:3,
        image:"28.jpg",
        content:"Select a music <strong>section</strong> and then (1) either make the track longer by duplicating the selected <strong>section</strong> or (2) make it shorter by removing the selected <strong>section</strong>.",
        position:{
            top:"45%",
            left:"88%"
        },
        offset:{
            top:-30,
            left:30
        },
        element:".timeline-action-remove",
    },
    {
        title:"Undo and Redo",
        step:3,
        image:"29.jpg",
        content:`Use these to undo or redo your input. <span class="filmstro-yellow">Tip:</span> <strong>Copy and paste</strong> also works in the timeline. Select one or more <strong>blocks</strong> and Cmd-C. Navigate to another area and then Cmd-V.`,
        position:{
            top:"45%",
            left:"95%"
        },
        offset:{
            top:-30,
            left:30
        },
        element:".timeline-action-undo",
    },
    {
        title:"Music Section",
        step:3,
        image:"30.jpg",
        content:`This is a music <strong>section</strong>. You only need to use this if you want to add more <strong>sections</strong> (to make the track longer) or remove some <strong>sections</strong> (to make it shorter). <span class="filmstro-yellow">Tip:</span> tracks can't be shorter than 3 sections.`,
        position:{
            top:"58%",
            left:"40%"
        },
        element:".filmstro-timeline-column",
    },
    {
        title:"Music Block",
        step:3,
        image:"31.jpg",
        content:"This is the smallest area you can work with. You can also shift-select or command/ctrl-select multiple <strong>blocks</strong> to affect them all at once. ",
        position:{
            top:"70%",
            left:"31%"
        },
        offset:{
            top:30
        },
        element:".filmstro-timeline-column .daw-item-main",
    },
    {
        title:"Music Versions",
        step:3,
        image:"32.jpg",
        content:"You can save up to 3 versions of 1 track in your session. Simply load Version 1 or 2 and make some other changes. Make sure to save in order to keep the changes you make in each version!",
        position:{
            top:"82%",
            left:"10%"
        },
        element:".song-versions-wrapper",
    },
    {
        title:"Timeline zoom",
        step:3,
        image:"33.jpg",
        content:"Use this to increase or decrease the view of the timeline horizontally.",
        position:{
            top:"83%",
            left:"30%"
        },
        offset:{
            top:-30
        },
        element:".zoom-wrapper",
    },
    {
        title:"Save & Export",
        step:3,
        image:"34.jpg",
        content:"Save & Export using these buttons. On export you have a choice to export (1) the soundtrack or (2) the unique template of musical keyframes you have created (separate from the music) which can be uploaded to future sessions. ",
        position:{
            top:"84%",
            left:"62%"
        },
        offset:{
            top:5
        },
        element:".footer-session-actions",
    },
    {
        title:"Music Volume",
        step:3,
        image:"35.jpg",
        content:"Set the volume of the music and balance it with your video's volume to get a quick idea of how the music will compliment your edit. ",
        position:{
            top:"82%",
            left:"82%"
        },
        element:".volume-slider-parent",
    },
    {
        title:"Individual stem control (beta)",
        step:3,
        image:"36.jpg",
        content:"Click the headphones to identify the sound(s) you don't want. Then uncheck the sound(s) and when you export the sound(s) will not be included in your final music file. ",
        position:{
            top:"22%",
            left:"17%"
        },
        condition:{
            type:"state",
            variable:"edit/current_edit_view",
            operator:"EQUALS",
            value:"filmstro-stems"
        },
        element:".stems-wrapper",
    },
    {
        title:"Music download",
        step:3,
        image:"37.jpg",
        content:`Once you export a soundtrack it will appear here from where you can download it. <span class="filmstro-yellow">Tip:</span> these files will only appear here once. After you close Filmstro they are removed so remember to download them immediately after export. `,
        position:{
            top:"22%",
            left:"17%"
        },
        condition:{
            type:"state",
            variable:"edit/current_edit_view",
            operator:"EQUALS",
            value:"rendered-themes"
        },
        element:".rendered-themes",
    },
    {
        title:"Video volume",
        step:3,
        image:"38.jpg",
        content:"You can change the volume of your video using these controls and mute/unmute it.",
        position:{
            top:"18%",
            left:"78%"
        },
        condition:{
            type:"state",
            variable:"video_source",
            operator:"NOT_EMPTY",
            value:false
        },
        offset:{
            top:100,
        },
        element:".video-box-wrapper",
    },
    {
        title:"Video offset (beta)",
        step:3,
        image:"39.jpg",
        content:"This changes the synchronisation of the video with the music by the number of frames you input (24fps). Move the handle or type into the input field. Left (-): video playback starts sooner. Right (+): video playback starts later. ",
        position:{
            top:"28%",
            left:"74%"
        },
        condition:{
            type:"state",
            variable:"video_source",
            operator:"NOT_EMPTY",
            value:false
        },
        element:".offset-timeline-wrapper",
    },
];



export default hotspots;