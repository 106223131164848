<template>

    <modal class="tutorial-modal" v-if="show_tutorial_modal"  @close="closeModal" :show_close_button="true" :show_the_content="show_tutorial_modal">
      <template v-slot:body>
        <div class="video-modal-box">
          <div class="welcome-video">

              <div v-if="!play_video" class="tutorial-content" :style="`background-image:url(${bg_image})`" >
                <img loading="lazy"  class="logo" src="@/assets/filmstro-logo.png" alt="Filmstro Logo">
                <h3 >{{$t('tutorial_modal.title')}}</h3>
                <div class="modal-emoji">🤓</div>
              </div>

            <div v-if="play_video" style="padding:56.25% 0 0 0;position:relative;">
              <iframe :src="video_src" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
            </div>

          </div>
          <div class="welcome-description" v-if="!play_video" v-html="$t('tutorial_modal.description')">
          </div>

            <button v-if="!play_video" @click="watchTutorialVideo" class="btn comman-btn modal-btn">{{$t('tutorial_modal.watch_button')}}</button>

          <div class="flex gap-1 modal-actions">

            <h6 v-if="!play_video"><button  class="clear-button" @click="showTutorialLater" >{{$t('tutorial_modal.do_later_button')}}</button></h6>

            <h6><button class="clear-button" @click="neverShowTutorial" >{{$t('tutorial_modal.dont_show_again')}}</button></h6>
          </div>
        </div>
      </template>
    </modal>


</template>
<script>
import {computed, ref} from 'vue'
import {useStore} from 'vuex'

export default {

    setup(){
        let TUTORIAL_VIDEO_URL = "https://player.vimeo.com/video/791126373?h=2d8bbc8ba1&title=0&byline=0&portrait=0&autoplay=1";
        let video_src = ref( "");
        let play_video = ref( false );
        let show_close_button = ref( false );
        let bg_image = require( '@/assets/images/tutorial-bg.png' );
        const store = useStore();

        let show_tutorial_modal = computed(()=>store.state.show_tutorial_modal)

        let closeModal = ()=>{
          neverShowTutorial();
        }
        let showTutorialLater = ()=>{
          store.commit('setShowTutorialModal',false);
          localStorage.setItem('viewed_tutorial_modal',true);
        }

        let neverShowTutorial = ()=>{
          store.commit('setShowTutorialModal',false);
          localStorage.setItem('never_show_tutorial_modal',true);
        }

        let watchTutorialVideo = ()=>{
          video_src.value = TUTORIAL_VIDEO_URL;
          play_video.value = true;

          localStorage.setItem('viewed_welcome_modal',true);
          setTimeout(() => {
            show_close_button.value = true;
          }, 3000);
        }
        return {
            show_tutorial_modal,
            video_src,
            play_video,
            show_close_button,
            bg_image,
            watchTutorialVideo,
            neverShowTutorial,
            showTutorialLater,
            closeModal,
        }
    }
}
</script>

<style lang="scss">
.tutorial-modal {

  .modal-dialog {
      background: white;
      border-radius: 5px;
  }

  .welcome-video {
      height: 220px;
  }

  .welcome-video iframe {
      width: 100%;
      height: 100%;
  }
  .video-modal-box p{
    font-size: 0.875rem;
  }

  .modal-body{
    width: 450px;
  }
  .welcome-description{
    margin-bottom: 20px;
    p{
      margin-bottom: 30px;
      margin-top: 30px;
    }
  }
  .modal-actions{
    justify-content: center;
    gap: 20px;
    display: flex;
    button{
      font-size: 11px;
    }
  }

  .tutorial-content {
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 27px;
    line-height: 35px;
    h3 {
      color: white;
      font-weight: 800;
    }
    .modal-emoji{
      margin-top: 5px;
    }

  }
  img.logo {
    margin-bottom: 10px;
    width: 60px;
  }

}
</style>