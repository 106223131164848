<template>
    <modal class="welcome-modal" v-show="show_welcome_modal"  @close="closeModal" :show_close_button="show_close_button" :show_the_content="show_welcome_modal">
      <template v-slot:body>
        <div class="video-modal-box" v-if="show_welcome_modal">
          <div class="welcome-video" >
              <img @click="watchTutorialVideo" loading="lazy"  v-if="!play_video" src="@/assets/images/welcome-modal.png" alt="">
            <div v-if="play_video" style="padding:56.25% 0 0 0;position:relative;">
              <iframe :src="video_src" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
          <h2> {{$t('welcome_modal.title',{ user:user.first_name })}}</h2>
          <div class="welcome-description" v-html="$t('welcome_modal.description')"></div>
          <button @click="watchTutorialVideo" class="btn comman-btn modal-btn">{{$t('welcome_modal.watch_button')}}</button>
          <h6><button class="clear-button" @click="startBrowseOnboarding" >{{$t('welcome_modal.do_later_button')}}</button></h6>
        </div>
      </template>
    </modal>

</template>
<script>
import {computed, ref} from 'vue'
import {useStore} from 'vuex'

export default {
    setup(){

        let video_src = ref("https://player.vimeo.com/video/781103206?h=2d8bbc8ba1&title=0&byline=0&portrait=0");
        let play_video = ref( false );
        let show_close_button = ref( false );
        const store = useStore();

        let show_welcome_modal = computed(()=>store.state.show_welcome_modal)

        let user = computed(()=>store.getters.CURRENT_USER)

        let closeModal = ()=>{
          startBrowseOnboarding();
        }
        let startBrowseOnboarding = ()=>{
          store.commit('setShowWelcomeModal',false);
          // store.commit('setOnboardingStep',0);
          localStorage.setItem('viewed_welcome_modal',true);

          if( !store.getters.video_source ){
              // let demo_video = "/video/DemoFootage.mp4";
              // store.getters.video_element.src = demo_video;
              // store.commit('setVideoSource',demo_video);
              // store.commit('setVideoFileInfo',{name:"demo",size:"1024"});
          }
        }

        let watchTutorialVideo = ()=>{
          video_src.value = "https://player.vimeo.com/video/781103206?h=2d8bbc8ba1&title=0&byline=0&portrait=0&autoplay=1";
          play_video.value = true;

          localStorage.setItem('viewed_welcome_modal',true);
          setTimeout(() => {
            show_close_button.value = true;
          }, 3000);
        }
        return {
            show_welcome_modal,
            user,
            video_src,
            play_video,
            show_close_button,
            watchTutorialVideo,
            startBrowseOnboarding,
            closeModal,
        }
    }
}
</script>

<style lang="scss">
.welcome-modal {

  .modal-dialog {
      background: white;
      border-radius: 5px;
  }

  .welcome-video {
      height: 220px;
      img{
        cursor: pointer;
      }
  }

  .welcome-video iframe {
      width: 100%;
      height: 100%;
  }

  .modal-body{
    width: 450px;
  }
  .welcome-description{
    margin-bottom: 20px;
  }
  .welcome-description p{
    margin-bottom: 10px;
  }
}
</style>