<template>
    <header class="main-header">
        <div class="top-bar-logo-wrapper" v-if="$route.name == 'browse'">
            <figure class="logo-figure">
                <a target="_blank" href="https://filmstro.com"><img loading="lazy" src="@/assets/logo.png" class="logo"
                        alt="logo"></a>
            </figure>
            <filmstro-icon name="home" type="image" @click="goToHome" />
        </div>
        <ul class="top-nav">

            <li :class="{ 'active': $route.name == 'browse' }"> <a @click="goToHome" href="#">{{ $t('top_menu.browse') }}</a></li>
            <li v-tippy="{ content: `This tab opens the edit page. <br> You need to select a track first and add it by clicking the pencil icon on the track card or clicking the 'customise music' button. `, placement: 'bottom' }"
                class="edit-top-link" v-if="$route.name == 'edit' || current_session || selected_theme || last_edit_link"
                :class="{ 'active': $route.name == 'edit' }">
                <router-link :to="get_edit_screen_link">{{ $t('top_menu.edit') }}</router-link>
            </li>
            <li v-tippy="{ content: `This tab displays your previously saved sessions.<br> You can delete and rename them. Most recent sessions display at the top of the list.`, placement: 'bottom' }"
                :class="{ 'active': current_edit_view == 'my-sessions' }"><a @click.prevent="maybeOpeSidebar('my-sessions')"
                    href="#">{{ $t('top_menu.my_sessions') }} <filmstro-icon v-if="!USER_IS_LOGGED_IN" name="padlock"
                        type="image" /></a></li>
            <li id="my-favourites-link"
                v-tippy="{ content: `This tab displays your favourite tracks. You can remove these by clicking the favourite icon. New favourites are added to the bottom of the list.`, placement: 'bottom' }"
                :class="{ 'active': current_edit_view == 'my-favourites' }">
                <a @click.prevent="maybeOpeSidebar('my-favourites')" href="#">{{ $t('top_menu.my_favourites') }}
                    <filmstro-icon v-if="!USER_IS_LOGGED_IN" name="padlock" type="image" />
                    <span class="top-bar-menu-badge"><span class="top-bar-badge-count">{{ user_favourites.length
                    }}</span></span>
                </a>
            </li>

        </ul>
        <!-- <div class="top-bar-toggle toggle-input-wrapper noselect" v-if="false">
            <label class="toggle-input-label" for="tooltips-toggler"><filmstro-icon name="info-filled" /></label>
            <input id="tooltips-toggler" type="checkbox" class="toggle-input" @click="showOnboardingHotSpots($event)"
                :checked="onboarding_hotspots_visible" />
            <label class="toggle-label" for="tooltips-toggler">Sample Rate</label>
            <span></span>
            <label class="toggle-input-label" for="tooltips-toggler">Tooltips</label>
        </div> -->
        <ul class="top-nav right-side">
            <li v-if="!USER_IS_LOGGED_IN" ><a :href="`${website_url()}/pricing`">Pricing</a></li>
        </ul>
        <button v-if="!USER_IS_LOGGED_IN && ($route.name == 'edit' || $route.name == 'browse')"
            @click="showRemoveWaterMarkModal" class="calear-button top-right-login-btn"> {{ $t('login_button_text') }}
        </button>
    </header>
</template>
<script>
// import OnboardingSteps from '@/components/OnboardingSteps.vue';

import { mapGetters, mapMutations } from 'vuex'
import { website_url } from '@/includes/helpers';

export default {
    name: "TopBar",
    components: {
        // OnboardingSteps
    },
    computed: {
        ...mapGetters(['user_has_active_license', 'onboarding_hotspots_visible', 'user_favourites', 'USER_IS_LOGGED_IN']),
        ...mapGetters('edit', ['current_session', 'last_edit_link', 'current_edit_view']),
        ...mapGetters('browse', ['breadcrumbs', 'selected_theme']),
        // ...mapGetters('auth',['USER_IS_LOGGED_IN']),
        get_edit_screen_link() {
            if (this.$route.name == 'edit') {
                return this.$route.fullPath;
            }
            let url = "/edit";
            if (this.selected_theme) {
                url = `/edit/${this.selected_theme.theme_name}`
            }
            if (this.current_session) {
                url = `/edit/${this.current_session.theme}/main/${this.current_session.session_id}`
            }
            if (this.last_edit_link) {
                url = this.last_edit_link;
            }
            return url;
        },
        get_browse_link() {

            let url = '/browse';
            if (this.breadcrumbs.length && this.$route.name !== 'browse') {
                let _bcumb = this.breadcrumbs[this.breadcrumbs.length - 1];
                if (_bcumb.link) {
                    url = _bcumb.link;
                }
            }
            return url;
        }
    },
    mounted() {
        // this.showOnboardingHotSpots();
    },
    methods: {
        ...mapMutations(['setFlyoutContentView']),
        maybeOpeSidebar(view) {
            if (this.USER_IS_LOGGED_IN) {
                this.setFlyoutContentView(view)
            } else {
                this.$store.commit('setShowLoginRequiredModal', true);
                // this.$store.commit('setShowPremiumFeatureModal',true);
                return false;
            }
        },
        website_url,
        goToHome($event) {
            $event.preventDefault();
            this.$store.dispatch('browse/setDefaultCollectionAndFeaturedPlaylists', false);
            this.$router.push("/browse");
        },
        // deprecated?
        goToProURL(url) {

            if (this.user_has_active_license) {
                this.$router.push(url);
            } else {
                this.$store.commit('setShowPremiumFeatureModal', true);
                return false;
            }
        },
        showOnboardingHotSpots() {
            if (this.onboarding_hotspots_visible) {
                this.$store.commit('setOnboardingHotSpotsVisible', false);
                return;
            };
            // if( !this.$store.getters.video_source ){
            //     let demo_video = "/video/DemoFootage.mp4";
            //     this.$store.getters.video_element.src = demo_video;
            //     this.$store.commit('setVideoSource',demo_video);
            //     this.$store.commit('setVideoFileInfo',{name:"demo",size:"1024"});
            // }
            // if( this.$route.name == 'browse'  ){
            //     this.$store.commit('setOnboardingStep',0);
            // }
            // else if( this.$route.name == 'edit'  ){
            //     if( this.$route.query && this.$route.query.template ){
            //     this.$store.commit('setOnboardingStep',3);
            //     }else{
            //     this.$store.commit('setOnboardingStep',2);
            //     }
            // }

            setTimeout(() => {
                this.$store.commit('setOnboardingHotSpotsVisible', true);
            }, 200);
        },
        showRemoveWaterMarkModal() {
            this.$store.commit('setLoginModalVisible', true);
            return;
            let url = this.$route.path;
            this.$store.commit('setShowLoader', true);
            this.$store.commit('setLoaderMessage', 'Redirecting to login page')
            this.$store.dispatch('setLoginRedirectURL', url);
            return;
            if (!this.USER_IS_LOGGED_IN) {
                return;
            } else {
                if (!this.user_has_active_license) {
                    this.$store.commit('setLoaderMessage', 'Redirecting to pricing page')
                    window.open(`${website_url()}/pricing?origin=app`);
                    this.$store.commit('setShowLoader', false);
                }
            }
        }
    }
}
</script>


<style lang="scss">
/* header */
.main-header {
    // background-color: #292938;
    background-color: #15151C;
    padding: 5px 25px;
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 60px;
    // position: fixed;
    // top: 0;
    // left: 0;
    // width: 100%;
    // z-index: 2;
}

.top-nav {
    list-style: none;
    margin: 10px 0 0 0;
    min-width: 370px;

    &.right-side {
        margin-left: auto;
        width: auto;
        min-width: 0;
        margin-right: 20px;
    }

    li {
        display: inline-block;
        margin: 0 28px 0 0;

        a {
            color: #9b9b9b;
            font-size: 0.875rem;
            line-height: normal;
            display: flex;
            align-items: center;
            padding: 0 0 23px 0;
            position: relative;
        }

        a::before {
            content: "";
            width: 100%;
            height: 2px;
            background-color: var(--filmstro-yellow);
            position: absolute;
            bottom: -2px;
            left: 0;
            opacity: 0;
        }

    }

    li:last-child {
        margin-right: 0;
    }
}



.main-header .filmstro-icon {
    max-width: 25px;
    max-height: 17px;
    margin-left: 5px;
}

.main-header ul li.edit-top-link a::before {
    background-color: var(--filmstro-blue);
}

.main-header ul li.active a {
    font-weight: 600;
    color: #fff;
}

.main-header ul li.active a::before {
    opacity: 1;
}

.filmstro-home-icon {
    margin-bottom: 12px;
}

.top-right-login-btn {
    width: 178px;
    height: 40px;
    background-color: transparent;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    line-height: 36px;
    border-radius: 13px;
    border: 2px solid #fb9102;
    display: table;
    cursor: pointer;
    animation: shakeMe 5s 2 ease-in;
    animation-delay: 10s;
    // animation-name: shakeMe;
    // animation-duration: 5s;
    // animation-iteration-count: 2;
    // animation-timing-function: linear;
}

.top-right-login-btn img {
    margin: -2px 9px 0 0;
    display: inline-block;
    vertical-align: middle;
}

button.hotspots-toggler {
    width: 45px;
    // margin-right: auto;
    margin-left: 30px;
    // position: absolute;
    // top: 30px;
    // left: 50%;
    margin-right: auto;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--filmstro-yellow);
    gap: 5px;
    z-index: 9999999;

    .filmstro-icon {
        max-width: 45px;
        max-height: 35px;
    }

    .active-icon {
        display: none;
    }

    span {
        display: inline-block;
        margin-bottom: 2px;
    }

    &:hover {
        .active-icon {
            display: block;
        }

        .default-icon {
            display: none;
        }
    }
}

.top-bar-toggle.toggle-input-wrapper {
    display: flex;
    gap: 10px;
    margin-top: -10px;
    z-index: 9999;
    margin-right: auto;
    margin-left: 40px;
    align-items: center;

    .filmstro-icon {
        max-width: 30px;
        max-height: 25px;
    }

    label {
        cursor: pointer;
    }
}

.top-bar-menu-badge {
    display: flex;
    vertical-align: middle;
    width: 15px;
    height: 15px;
    background-color: var(--filmstro-yellow);
    border-radius: 50%;
    position: absolute;
    top: 10px;
    right: -15px;
    font-size: 10px;
    justify-content: center;
    align-items: center;
    color: white;
    color: black;
    opacity: 0;
    pointer-events: none;
    transition: all .4s ease;

    &.show-badge {
        opacity: 1;
        top: -10px;
        ;
    }
}

@media screen and (max-width:1500px) {
    .top-right-login-btn {
        max-width: 120px;
        height: 25px;
        line-height: 23px;
    }
}

.top-bar-logo-wrapper {
    width: 274px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 15px;

    .filmstro-icon {
        cursor: pointer;
    }
}


@media screen and (max-width: 800px) {
    .top-bar-logo-wrapper {
        width: auto;
        width: 90px;
    }

    figure.logo-figure {
        margin-bottom: 5px;
    }

    figure.logo-figure img {
        width: 22px;
    }

    .top-nav li {
        margin-right: 10px;
    }

    .top-bar-toggle {
        display: none !important;
    }

    ul.top-nav {
        min-width: 0;
    }
}</style>