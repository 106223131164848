import hotkeys from "hotkeys-js";

export let filmstro_hotkeys = {

    bind_hotkeys : () => {
        console.warn('INIT HOTKEYS')
        // window.stroInterface.isAltLeftKey = false
        // window.stroInterface.isShiftLeftKey = false
        // window.stroInterface.isControlLeftKey = false
        // window.stroInterface.isMetaLeftKey = false


        window.addEventListener('keydown', (e) => {
            if(window.stroInterface){
                if (e.code == "AltLeft" || e.code == "CtrlLeft") {
                    window.stroInterface.isAltLeftKey = true
                }

                if (e.code == "ShiftLeft") {
                    window.stroInterface.isShiftLeftKey = true
                }

                if (e.code == "MetaLeft") {
                    window.stroInterface.isMetaLeftKey = true
                }

                if (e.code == "ControlLeft") {
                    window.stroInterface.isControlLeftKey = true
                }
            }


        });


        window.addEventListener('keyup', (e) => {

            if(window.stroInterface){
                if (e.code == "AltLeft" || e.code == "CtrlLeft") {
                    window.stroInterface.isAltLeftKey = false
                }
                if (e.code == "ShiftLeft") {
                    window.stroInterface.isShiftLeftKey = false
                }
                if (e.code == "MetaLeft") {
                    window.stroInterface.isMetaLeftKey = false
                }
                if (e.code == "ControlLeft") {
                    window.stroInterface.isControlLeftKey = false
                }
            }

        });


        hotkeys('1',{keyup:true,keydown:false},function(){
            if( !window.stroDaw ) return;
            window.stroDaw.doRadios(0);
        });
        hotkeys('2',{keyup:true,keydown:false},function(){
            if( !window.stroDaw ) return;
            window.stroDaw.doRadios(1);
        });
        hotkeys('3',{keyup:true,keydown:false},function(){
            if( !window.stroDaw ) return;
            window.stroDaw.doRadios(2);
        });

        hotkeys('space,shift+space',(e)=>{

            if( !window.stroInterface && !window.stroDaw ){
                return;
            }
            e.preventDefault();
            stroInterface.togglePlay();
        })

    },
    unbind_hotkeys:()=>{
        console.log('should unbind')
        hotkeys.unbind();
    }
}