<template>
    <div class="sidebar-col">
          <div class="sidebar">
            <figure class="logo-figure">
              <a target="_blank" href="https://filmstro.com"><img loading="lazy"  src="@/assets/logo.png" class="logo" alt="logo"></a>
            </figure>
            <slot>
              <div class="sidebar-top-menu">
                <ul>
                  <li @click="changeEditView('presets')" :class="{'active':current_edit_view=='presets'}" class="sidebar-menu-action has-active-state" v-if="$route.name=='edit'" v-tippy="{ content:`From here you can choose a template or upload your own`,placement:'right'}">
                    <filmstro-icon class="default-icon" name="templates" />
                    <filmstro-icon class="active-icon" name="templates-active" />
                  </li>

                  <li @click="changeEditView('transitions')" :class="{'active':current_edit_view=='transitions'}" class="sidebar-menu-action has-active-state" v-if="$route.name=='edit'" v-tippy="{ content:`From here you can add additional transitions if you prefer not to create your own in real time`,placement:'right'}">
                    <filmstro-icon class="default-icon" name="transitions" />
                    <filmstro-icon class="active-icon" name="transitions-active" />
                  </li>

                  <li @click="changeEditView('filmstro-stems')" :class="{'active':current_edit_view=='filmstro-stems'}" class="sidebar-menu-action has-active-state" v-if="$route.name=='edit'" v-tippy="{ content:`Choose which sounds to exclude from your export`,placement:'right'}">
                    <filmstro-icon class="default-icon" name="stems"/>
                    <filmstro-icon class="active-icon" name="stems-active"/>
                  </li>

                  <li @click="changeEditView('midi-controllers')" :class="{'active':current_edit_view=='midi-controllers'}" class="sidebar-menu-action has-active-state" v-if="$route.name=='edit'" v-tippy="{ content:`Control Filmstro sliders, play/pause and stop using a USB MIDI controller`,placement:'right'}">
                    <filmstro-icon class="default-icon" name="midi-controllers"/>
                    <filmstro-icon class="active-icon" name="midi-controllers-active"/>
                  </li>

                  <li @click="changeEditView('rendered-themes')" :class="{'active':current_edit_view=='rendered-themes'}" class="sidebar-menu-action has-active-state" v-if="$route.name=='edit'" v-tippy="{ content:`Once you export a soundtrack it will appear here from where you can download it. <br><span class='filmstro-yellow'>Tip:</span> these files will only appear here once. After you close Filmstro they are removed so remember to download them immediately after export.`,placement:'right'}">
                    <filmstro-icon class="default-icon" name="export"/>
                    <filmstro-icon class="active-icon" name="export-active"/>
                    <span class="sidebar-item-badge" v-show="rendered_themes.length">{{rendered_themes.length}}</span>
                  </li>
                </ul>
              </div>
            </slot>

            <div class="sidebar-bottom-menu">
              <span class="separator"></span>
              <ul class="sidebar-bottom-menu">

                <li class="sidebar-menu-action has-active-state knowledge-base-link no-link-while-onboarding" title="View this THEME in the CMS" v-if="current_playing_theme && CURRENT_USER && CURRENT_USER.is_admin ">
                  <!-- <a target="_blank" href="https://www.youtube.com/c/FilmstroPro"> -->
                    <a target="_blank" :href="`${website_url}/wp-admin/post.php?post=${current_playing_theme.theme_id}&action=edit`" >
                      <filmstro-icon class="default-icon" name="edit-icon"/>
                      <filmstro-icon class="active-icon" name="edit-icon" />
                    </a>
                </li>

                <li class="sidebar-menu-action has-active-state knowledge-base-link no-link-while-onboarding" title="View this TRACK in the CMS" v-if="current_playing_theme && CURRENT_USER && CURRENT_USER.is_admin ">
                  <!-- <a target="_blank" href="https://www.youtube.com/c/FilmstroPro"> -->
                    <a target="_blank" :href="`${website_url}/wp-admin/post.php?post=${current_playing_theme.id}&action=edit`" >
                      <filmstro-icon class="default-icon" name="edit-icon"/>
                      <filmstro-icon class="active-icon" name="edit-icon" />
                    </a>
                </li>

                <li class="sidebar-menu-action has-active-state knowledge-base-link no-link-while-onboarding" v-tippy="{content: `This is where you can find tutorials, walkthrough videos and more.`, placement: 'right'}" >
                  <!-- <a target="_blank" href="https://www.youtube.com/c/FilmstroPro"> -->
                  <a target="_blank" href="https://filmstro.com/knowledge-base">
                    <filmstro-icon class="default-icon" name="knowledge-base" />
                    <filmstro-icon class="active-icon" name="knowledge-base-active" />
                  </a>
                </li>
                <li v-tippy="{  content: `This takes you straight to our contact page. We're based in the UK and are available between 9-5 on weekdays.`, placement: 'right'}" data-feedback-trigger class="sidebar-menu-action has-active-state contact-link no-link-while-onboarding">
                  <a data-feedback-trigger target="_blank" href="https://filmstro.com/contact">
                    <filmstro-icon class="default-icon" name="contact"/>
                    <filmstro-icon class="active-icon" name="contact-active"/>
                  </a>
                </li>
              </ul>

              <span class="separator"></span>

              <!-- <div class="user-data user-profile-link no-link-while-onboarding"> -->
                <!-- <span @click="toggleUserMenu" class="user-btn has-active-state">
                  <img loading="lazy"  class="default-icon" src="@/assets/icons/my-account.svg" alt="user-icon">
                  <img loading="lazy"  class="active-icon" src="@/assets/icons/my-account-active.svg" alt="user-active-icon">
                </span>
                <div class="user-menu">
                  <ul>
                    <li class="sidebar-menu-actiona"> <a target="_blank" :href="account_url">{{$t( 'sidebar_menu.my_account')}}</a></li>
                    <li v-if="false" class="sidebar-menu-actiona"> <a href="#" @click="showWelcomeModal">Show welcome(DEV)</a></li>
                    <li @click="showAboutModal()" class="sidebar-menu-actiona"> <a>{{$t( 'sidebar_menu.about')}}</a></li>
                    <li class="sidebar-menu-actiona" @click.prevent="openPreferencesModal" > <a href="#" >{{$t( 'sidebar_menu.preferences')}}</a></li>
                  </ul>
                </div> -->

              <!-- </div> -->
              <ul class="sidebar-bottom-meanu">
                <li class="sidebar-menu-action has-active-state knowledge-base-link no-link-while-onboarding" v-tippy="{content: `This section contains a link to your account.`, placement: 'right'}" >
                  <!-- <a target="_blank" href="https://www.youtube.com/c/FilmstroPro"> -->
                  <a target="_blank" :href="account_url">
                    <!-- <img loading="lazy"  class="default-icon" src="@/assets/icons/my-account.svg" alt="user-icon">
                    <img loading="lazy"  class="active-icon" src="@/assets/icons/my-account-active.svg" alt="user-active-icon"> -->
                    <filmstro-icon class="default-icon" name="my-account"/>
                    <filmstro-icon class="active-icon" name="my-account-active"/>
                  </a>
                </li>

                <li v-if="$route.name=='edit'" @click="showAboutModal()" class="sidebar-menu-action has-active-state contact-link no-link-while-onboarding" v-tippy="{content: `This section contains information about the current version of Filmstro.`, placement: 'right'}" >
                  <a>
                    <filmstro-icon class="default-icon" name="info-icon"/>
                    <filmstro-icon class="active-icon" name="info-icon-active"/>
                  </a>
                </li>

                <li v-if="$route.name=='edit'" @click.prevent="openPreferencesModal" data-feedback-trigger class="sidebar-menu-action has-active-state contact-link no-link-while-onboarding" v-tippy="{content: `This section contains preferences, where you can toggle the sample rate if you experience audio issues.`, placement: 'right'}" >
                  <a>
                    <filmstro-icon class="default-icon" name="wrench"/>
                    <filmstro-icon class="active-icon" name="wrench-active"/>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
</template>
<script>
import { account_url,website_url } from '@/includes/helpers';

import { mapGetters, mapMutations } from 'vuex';
export default {
  computed:{
    ...mapGetters('edit',['current_edit_view','rendered_themes']),
    ...mapGetters('browse',['current_playing_theme']),
    ...mapGetters(['onboarding_hotspots_visible','CURRENT_USER']),
    account_url(){
      return account_url();
    },
    website_url(){
      return website_url();
    }
  },
  methods:{
    ...mapMutations('edit',['setCurrentEditView']),
    ...mapMutations(['setPreferencesModalVisible']),
    // showWelcomeModal(){
    //   localStorage.setItem( 'onboarding_steps_complete',false )
    //   this.$store.commit('setShowWelcomeModal',true);
    //   this.toggleUserMenu();
    // },
    changeEditView( view ){
      this.setCurrentEditView(view);
    },
    toggleUserMenu(){
      // document.querySelector('.user-menu').classList.toggle('show');
    },
    openPreferencesModal(){
      this.toggleUserMenu();
      this.setPreferencesModalVisible( true );
    },
    showAboutModal(){
      this.toggleUserMenu();
      this.$store.commit('setShowAboutFilmstroModal',true);
    }


  },
  mounted(){
    // this.showOnboardingHotSpots();
  }
}
</script>

<style scoped lang="scss">


/* sidebar */

.sidebar-col{
	width: 92px;
  height: 100%;
  position: fixed;
  left: 0;
  z-index: 999;
}
.sidebar{
	width: 100%;
	height: 100%;
	background-color: #15151C;
	padding: 29px 22.5px 30px 22.5px;
	position: relative;
  display: flex;
  flex-direction: column;
}
.sidebar-bottom{
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	z-index: 111;
}

.logo-figure{
	width: 100%;
	padding-bottom: 20px;
	border-bottom: 2px solid #28272f;
}
.logo{
	margin: 0 auto;
}

.social-media{
	padding: 35px 0 1px 0;
	border-top: 2px solid #28272f;
	border-bottom: 2px solid #28272f;
	margin-bottom: 40px;
}
.social-media a{
	margin: 0 auto;
	margin-bottom: 30px;
	display: table;
}
.social-media a img{
	margin: 0 auto;
	text-align: center;
}
.user-data{
	margin-bottom: 155px;
	position: relative;
  margin-top: 20px;
}
.user-menu{
	position: absolute;
	top: 0;
	left: 63px;
	background-color: #fff;
	padding: 0;
	text-align: left;
	width: 0;
	opacity: 0;
	display: none;
	transition: all 0.5s ease;
}
.user-menu ul li a{
	margin: 0;
	color: #000;
	font-size: 0.875rem;
	font-weight: 600;
	line-height: 22px;
	display: block;
}
.user-btn{
	margin: 0 auto;
	display: table;
	cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  margin-top: 9px;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-menu.show{
	opacity: 1;
	width: 150px;
	display: block;
  z-index: 999;

}


.sidebar-menu-action {
  padding: 10px;
    border-radius: 5px;
    display: block;
    height: 40px;
    margin-top: 9px;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover{
      border: solid 1px #4A4A4A;
    }


    &.has-badge{
      .sidebar-item-badge{
        display: flex;
      }
    }

    img{
      margin: 0 auto;
      width: 25px;
    }

}

.has-active-state{
  .active-icon{
    display: none;
  }
      &:hover,&.active{
        background: #4A4A4A;
        .active-icon{
          display: block;
        }
        .default-icon{
          display: none;
        }
      }
    }
// .sidebar-menu-action:hover,
// .sidebar-menu-action.active{

// }

.sidebar ul {
    list-style: none;
    margin-top: 20px;
    margin-bottom: 20px;
}
.sidebar-bottom-menu {
    margin-top: auto;
}

.separator{
  border-top: 2px solid #28272f;
  display: block;
}


li.sidebar-menu-actiona {
    padding: 10px;
}

.sidebar .user-menu ul {
    margin: 0;
}

li.sidebar-menu-actiona:hover {
    background: var(--filmstro-blue);
    color: white;
}


span.sidebar-item-badge {
    position: absolute;
    top: -8px;
    right: -8px;
    display: block;
    border-radius: 50%;
    width: 5px;
    height: 5px;
    padding: 7px;
    background: #fff;
    border: 1px solid #000;
    color: #000;
    text-align: center;
    font-size: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
}



</style>