<template>
    <modal type="fullscreen" id="debug-modal" v-show="false" >
        <template v-slot:body>
            <div id="filmstro-debug" class="f-template-1" data-title="info (debug)">

                <div id="loggg3" class="loggg3">song infos</div>
                <div>
                    <br>
                    <textarea id="txt-eq">{"low": 10, "mid": 0, "high": 0}</textarea>
                    <div class="but3" onclick="stroDaw.doButton('set-eq')">set</div>

                    <textarea id="txt-compressor">{"attack" : 0.003, "knee" : 20, "ratio": 12, "release" : 0.25, "threshold" : -24}</textarea>
                    <div class="but3" onclick="stroDaw.doButton('set-compressor')">set compressor</div>
                    <textarea id="txt-limiter">{"threshold" : -24}</textarea>
                    <div class="but3" onclick="stroDaw.doButton('set-limiter')">set limiter</div>
                </div>

                <div id="all">
                <!-- <i class="material-icons icon-white">play_arrow</i>
                <i class="material-icons icon-white">brightness_6</i> -->

                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24"
                        width="24px" fill="currentColor" id="stop-circle-outlined">
                        <rect fill="none" height="24" width="24" />
                        <path
                            d="M12,2C6.48,2,2,6.48,2,12c0,5.52,4.48,10,10,10s10-4.48,10-10C22,6.48,17.52,2,12,2z M12,20c-4.42,0-8-3.58-8-8s3.58-8,8-8 s8,3.58,8,8S16.42,20,12,20z M16,16H8V8h8V16z" />
                        </svg>

                    </div>

                    <!-- <fieldset>
                        <legend>test</legend>
                    </fieldset> -->

                    <fieldset>
                        <legend>controls</legend>
                        <div class="test1">
                            <div id="loggg2" class="loggg">(no song)</div>
                        </div>

                        <div class="test1">
                            <div id="loggg" class="loggg">not playing</div>
                        </div>

                        <div class="test1">
                            <div id="loggg3" class="loggg">not playing</div>
                        </div>

                    </fieldset>

                    <p>Click anywhere in this window before you hit start.</p>

                    <details>
                        <summary>parameters</summary>
                        <div>
                            <input type="text" id="audio-pars-offset" value="0" onchange="stroInterface.setMetaOffset(this);"
                            title="offset">
                            <input type="text" id="audio-pars-bpm" value="0" onchange="stroInterface.setMetaBpm(this);" title="bpm">
                        </div>
                        <div>
                            <textarea id="audio-pars" value="0" title="eval" onfocus="stroInterface.stopPlay()"
                            onchange="stroInterface.setMeta(this);"></textarea>
                        </div>
                    </details>



                    <details>
                        <summary>stro manual old</summary>
                        <div style="display: grid; grid: auto / auto auto;">
                            <div>
                                <div style="display: grid;">
                                    <span>M <input id="inf_m" onchange="stroInterface.applyW()" type="range" min=0 max=1 step=1
                                    style="width: 60px"></span>
                                    <span>D <input id="inf_d" onchange="stroInterface.applyW()" type="range" min=0 max=1 step=1
                                    style="width: 60px"></span>
                                    <span>P <input id="inf_p" onchange="stroInterface.applyW()" type="range" min=0 max=1 step=1
                                    style="width: 60px"></span>
                                </div>
                            </div>
                            <div>
                                <button onclick="stroInterface.applyXYZ(this.innerText)">AAA</button>
                                <button onclick="stroInterface.applyXYZ(this.innerText)">BAA</button>
                                <button onclick="stroInterface.applyXYZ(this.innerText)">BBB</button>
                                <input type="text" onchange="stroInterface.applyXYZ(this.value)">
                            </div>
                        </div>

                    </details>

                    <details open>
                        <summary>inflections</summary>
                        <div style="display: grid; grid: auto / auto auto;">
                            <div>
                                <div style="display: grid;">
                                    <span><input id="inf2_m" onchange="stroInterface.applyU()" oninput="stroInterface.applyU()" type="range"
                                    min=0 max=2 step=0.001 style="width: 60px">M</span>
                                    <span><input id="inf2_d" onchange="stroInterface.applyU()" oninput="stroInterface.applyU()" type="range"
                                    min=0 max=2 step=0.001 style="width: 60px">D</span>
                                    <span><input id="inf2_p" onchange="stroInterface.applyU()" oninput="stroInterface.applyU()" type="range"
                                    min=0 max=2 step=0.001 style="width: 60px">P</span>
                                </div>
                            </div>
                        </div>


                    </details>

                    <details open>
                        <summary>inflection curves</summary>
                        <div>
                            <input id="stro-a" type="checkbox" onchange="stroInterface.setAutomation(this.checked);" class="test2"> enable
                        </div>
                        <div>
                            <input type="checkbox" onchange="stroPlayer.splayer.isAutomated=this.checked;" class="test2"> enable
                        </div>
                        <div>
                            <input type="checkbox" onchange="stroPlayer.splayer.isAutomated2=this.checked;" class="test2"> automation with
                            MDP
                        </div>
                        <div id="stro-chart"></div>
                    </details>


                    <details open>
                        <summary>chart</summary>
                    </details>

                    <details>
                        <summary>mixer</summary>
                        <div id="cont_main">
                        nothing to mix yet.
                        </div>
                    </details>

                    <details id="metamixer">
                        <summary>meta</summary>
                        <div>
                            <i title="offline render" class="material-icons icon-white" onclick="stroInterface.renderSession()">get_app</i>
                        </div>
                        <div id="cont_sec"></div>
                    </details>

                    <details id="vfiles">
                        <summary>files</summary>
                        <div>TBD</div>
                    </details>
                </div>
            </div>
        </template>
    </modal>

</template>

<style lang="scss">
#debug-modal{
    .modal-dialog {
        background-color: var(--filmstro-black);
        width: 100%;
        max-width: 100%;
    }
}
</style>