<template>
    <button :ref="`favourite-toggle-${theme_id}`" @click="onFavouriteClick" class="favourite-toggle">
        <!-- <img loading="lazy"  v-if="is_favourite" src="@/assets/icons/favourites-active.svg" > -->
        <!-- <img loading="lazy"  v-else src="@/assets/icons/favourites.svg" > -->
        <filmstro-icon v-if="is_favourite" name="heart-active"/>
        <filmstro-icon v-else name="heart"/>

        <svg v-if="false" id="heart-svg" viewBox="467 392 58 57" xmlns="http://www.w3.org/2000/svg">
            <g id="Group" fill="none" fill-rule="evenodd" transform="translate(467 392)">
                <path id="heart" fill="#AAB8C2" d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z" />
                <!-- <path id="heart" fill="#AAB8C2" d="m14.123 19.336c-.468.453-.944.913-1.426 1.381-.194.189-.446.283-.697.283s-.503-.094-.697-.283c-4.958-4.813-9.303-8.815-9.303-12.54 0-5.659 7.376-6.978 10-2.461 2.604-4.483 10-3.217 10 2.461 0 .68-.144 1.369-.41 2.07-1.061-1.02-2.503-1.648-4.09-1.648-3.256 0-5.9 2.644-5.9 5.9 0 2 .998 3.77 2.523 4.837zm3.378-9.336c2.58 0 4.499 2.107 4.499 4.499 0 2.58-2.105 4.499-4.499 4.499-2.586 0-4.5-2.112-4.5-4.499 0-2.406 1.934-4.499 4.5-4.499zm.5 3.999v-1.5c0-.265-.235-.5-.5-.5-.266 0-.5.235-.5.5v1.5h-1.5c-.266 0-.5.235-.5.5s.234.5.5.5h1.5v1.5c0 .265.234.5.5.5.265 0 .5-.235.5-.5v-1.5h1.499c.266 0 .5-.235.5-.5s-.234-.5-.5-.5z" /> -->
                <!-- <path id="heart" d="M14.123,19.336q-.7.679-1.426,1.381a1,1,0,0,1-1.394,0C6.345,15.9,2,11.9,2,8.177,2,2.518,9.376,1.2,12,5.716,14.6,1.233,22,2.5,22,8.177a5.823,5.823,0,0,1-.41,2.07,5.9,5.9,0,1,0-7.467,9.089ZM17.5,10A4.5,4.5,0,1,1,13,14.5,4.5,4.5,0,0,1,17.5,10Zm.5,4V12.5a.5.5,0,0,0-1,0V14H15.5a.5.5,0,0,0,0,1H17v1.5a.5.5,0,0,0,1,0V15h1.5a.5.5,0,0,0,0-1Z" transform="translate(4 5)" fill="#aab8c2" fill-rule="evenodd"></path> -->
                <circle id="main-circ" fill="var(--filmstro-yellow)" opacity="0" cx="29.5" cy="29.5" r="1.5"/>
                <g id="grp7" opacity="0" transform="translate(7 6)">
                    <circle id="oval1" fill="var(--filmstro-red)" cx="2" cy="6" r="2"/>
                    <circle id="oval2" fill="var(--filmstro-blue)" cx="5" cy="2" r="2"/>
                </g>

                <g id="grp6" opacity="0" transform="translate(0 28)">
                    <circle id="oval1" fill="var(--filmstro-red)" cx="2" cy="7" r="2"/>
                    <circle id="oval2" fill="#91D2FA" cx="3" cy="2" r="2"/>
                </g>

                <g id="grp3" opacity="0" transform="translate(52 28)">
                    <circle id="oval2" fill="var(--filmstro-red)" cx="2" cy="7" r="2"/>
                    <circle id="oval1" fill="var(--filmstro-blue)" cx="4" cy="2" r="2"/>
                </g>

                <g id="grp2" opacity="0" transform="translate(44 6)">
                    <circle id="oval2" fill="var(--filmstro-red)" cx="5" cy="6" r="2"/>
                    <circle id="oval1" fill="var(--filmstro-red)" cx="2" cy="2" r="2"/>
                </g>

                <g id="grp5" opacity="0" transform="translate(14 50)">
                    <circle id="oval1" fill="#91D2FA" cx="6" cy="5" r="2"/>
                    <circle id="oval2" fill="#91D2FA" cx="2" cy="2" r="2"/>
                </g>

                <g id="grp4" opacity="0" transform="translate(35 50)">
                    <circle id="oval1" fill="#F48EA7" cx="6" cy="5" r="2"/>
                    <circle id="oval2" fill="#F48EA7" cx="2" cy="2" r="2"/>
                </g>

                <g id="grp1" opacity="0" transform="translate(24)">
                    <circle id="oval1" fill="#9FC7FA" cx="2.5" cy="3" r="2"/>
                    <circle id="oval2" fill="#9FC7FA" cx="7.5" cy="2" r="2"/>
                </g>
            </g>
        </svg>
    </button>
</template>
<script>
// import { is_user_logged_in } from '@/includes/helpers';
import {mapActions, mapGetters} from 'vuex';
export default {

    props:{
        theme_id:{
            default:false,
            required:true
        },
        is_favourite:{
            default:false,
            required:true
        }
    },
    data(){
        return {
            is_requesting:false
        }
    },
    computed:{
      ...mapGetters(['USER_IS_LOGGED_IN'])
    },
    methods:{
        ...mapActions(['toggleFavourite']),
        async onFavouriteClick(){
            if( !this.USER_IS_LOGGED_IN ){
                this.$store.commit('setShowLoginRequiredModal',true);
                return;
            }

            this.$refs[ `favourite-toggle-${this.theme_id}` ].classList.toggle('is-active');

            let message = "Do you want to add this theme to your favourites?";
            if( this.is_favourite ){
                message = "Are you sure you want to remove this theme from your favourites?";
            }
            if(!this.is_requesting){
                this.is_requesting = true;
                this.toggleFavourite( this.$props.theme_id ).then(r=>{
                    if(r.status ==='success'){
                        this.$notification.success( this.is_favourite ? "Added" : "Removed", r.message  );
                        document.querySelector('.top-bar-menu-badge').classList.add('show-badge');
                        setTimeout(() => {
                          document.querySelector('.top-bar-menu-badge').classList.remove('show-badge');
                        }, 400 );
                    }
                    if(r.status ==='failed'){
                        this.$notification.warning( "Not added", r.message  );
                    }
                    this.$store.dispatch('fetchFavourites');
                    this.is_requesting = false;
                })
            }
        }
    }
}
</script>
<style scoped lang="scss">
    .favourite-toggle{
        font-size: 12px;
        width: 30px;
        height: 20px;
        border: 0;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background: transparent;
        cursor: pointer;
        display: flex;
        justify-content: center;
        img{
          max-height:100%;
        }
        &:hover img{
            transform: scale(1.1);
        }
        &:active img{
            transform: scale(.8);
        }
    }

  #heart{transform-origin:center; animation:animateHeartOut .3s linear forwards;}
  #main-circ{transform-origin:29.5px 29.5px;}

  .favourite-toggle.is-active svg{
    #heart{transform:scale(.2); fill:var(--filmstro-yellow); animation:animateHeart .3s linear forwards .25s;}
    #main-circ{transition:all 2s; animation:animateCircle .3s linear forwards; opacity:1;}
    #grp1{
      opacity:1; transition:.1s all .3s;
      #oval1{
        transform:scale(0) translate(0, -30px);
        transform-origin:0 0 0;
        transition:.5s transform .3s;}
      #oval2{
        transform:scale(0) translate(10px, -50px);
        transform-origin:0 0 0;
        transition:1.5s transform .3s;}
    }
    #grp2{
      opacity:1; transition:.1s all .3s;
      #oval1{
        transform:scale(0) translate(30px, -15px);
        transform-origin:0 0 0;
        transition:.5s transform .3s;}
      #oval2{
        transform:scale(0) translate(60px, -15px);
        transform-origin:0 0 0;
        transition:1.5s transform .3s;}
    }
    #grp3{
      opacity:1; transition:.1s all .3s;
      #oval1{
        transform:scale(0) translate(30px, 0px);
        transform-origin:0 0 0;
        transition:.5s transform .3s;}
      #oval2{
        transform:scale(0) translate(60px, 10px);
        transform-origin:0 0 0;
        transition:1.5s transform .3s;}
    }
    #grp4{
      opacity:1; transition:.1s all .3s;
      #oval1{
        transform:scale(0) translate(30px, 15px);
        transform-origin:0 0 0;
        transition:.5s transform .3s;}
      #oval2{
        transform:scale(0) translate(40px, 50px);
        transform-origin:0 0 0;
        transition:1.5s transform .3s;}
    }
    #grp5{
      opacity:1; transition:.1s all .3s;
      #oval1{
        transform:scale(0) translate(-10px, 20px);
        transform-origin:0 0 0;
        transition:.5s transform .3s;}
      #oval2{
        transform:scale(0) translate(-60px, 30px);
        transform-origin:0 0 0;
        transition:1.5s transform .3s;}
    }
    #grp6{
      opacity:1; transition:.1s all .3s;
      #oval1{
        transform:scale(0) translate(-30px, 0px);
        transform-origin:0 0 0;
        transition:.5s transform .3s;}
      #oval2{
        transform:scale(0) translate(-60px, -5px);
        transform-origin:0 0 0;
        transition:1.5s transform .3s;}
    }
    #grp7{
      opacity:1; transition:.1s all .3s;
      #oval1{
        transform:scale(0) translate(-30px, -15px);
        transform-origin:0 0 0;
        transition:.5s transform .3s;}
      #oval2{
        transform:scale(0) translate(-55px, -30px);
        transform-origin:0 0 0;
        transition:1.5s transform .3s;}
    }
    #grp2{opacity:1; transition:.1s opacity .3s;}
    #grp3{opacity:1; transition:.1s opacity .3s;}
    #grp4{opacity:1; transition:.1s opacity .3s;}
    #grp5{opacity:1; transition:.1s opacity .3s;}
    #grp6{opacity:1; transition:.1s opacity .3s;}
    #grp7{opacity:1; transition:.1s opacity .3s;}
}

@keyframes animateCircle{
  40%{transform:scale(10); opacity:1; fill:#DD4688;}
  55%{transform:scale(11); opacity:1; fill:#D46ABF;}
  65%{transform:scale(12); opacity:1; fill:var(--filmstro-red);}
  75%{transform:scale(13); opacity:1; fill:transparent; stroke:var(--filmstro-red); stroke-width:.5;}
  85%{transform:scale(17); opacity:1; fill:transparent; stroke:var(--filmstro-red); stroke-width:.2;}
  95%{transform:scale(18); opacity:1; fill:transparent; stroke:var(--filmstro-red); stroke-width:.1;}
  100%{transform:scale(19); opacity:1; fill:transparent; stroke:var(--filmstro-red); stroke-width:0;}
}

@keyframes animateHeart{
  0%{transform:scale(.2);}
  40%{transform:scale(1.2);}
  100%{transform:scale(1);}
}

@keyframes animateHeartOut{
  0%{transform:scale(1.4);}
  100%{transform:scale(1);}
}


</style>